// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-gatsby-mdx-mdx-wrappers-dir-e-83763-c-8-b-1-e-9673-b-92-d-31613-e-4806-b-86-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("./../../gatsby-mdx/mdx-wrappers-dir/e83763c8b1e9673b92d31613e4806b86--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-e-83763-c-8-b-1-e-9673-b-92-d-31613-e-4806-b-86-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-3-d-vr-models-mdx": () => import("./../../../src/pages/3d-vr-models.mdx" /* webpackChunkName: "component---src-pages-3-d-vr-models-mdx" */),
  "component---src-pages-about-orson-welles-the-heart-of-darkness-mdx": () => import("./../../../src/pages/about-orson-welles-the-heart-of-darkness.mdx" /* webpackChunkName: "component---src-pages-about-orson-welles-the-heart-of-darkness-mdx" */),
  "component---src-pages-about-the-research-group-mdx": () => import("./../../../src/pages/about-the-research-group.mdx" /* webpackChunkName: "component---src-pages-about-the-research-group-mdx" */),
  "component---src-pages-animations-mdx": () => import("./../../../src/pages/animations.mdx" /* webpackChunkName: "component---src-pages-animations-mdx" */),
  "component---src-pages-database-of-films-welles-watched-mdx": () => import("./../../../src/pages/database-of-films-welles-watched.mdx" /* webpackChunkName: "component---src-pages-database-of-films-welles-watched-mdx" */),
  "component---src-pages-heart-of-darkness-mdx": () => import("./../../../src/pages/heart-of-darkness.mdx" /* webpackChunkName: "component---src-pages-heart-of-darkness-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mapping-the-heart-of-darkness-team-mdx": () => import("./../../../src/pages/mapping-the-heart-of-darkness-team.mdx" /* webpackChunkName: "component---src-pages-mapping-the-heart-of-darkness-team-mdx" */),
  "component---src-pages-previous-explorations-js": () => import("./../../../src/pages/previous-explorations.js" /* webpackChunkName: "component---src-pages-previous-explorations-js" */),
  "component---src-pages-production-history-map-mdx": () => import("./../../../src/pages/production-history-map.mdx" /* webpackChunkName: "component---src-pages-production-history-map-mdx" */),
  "component---src-pages-the-animation-process-mdx": () => import("./../../../src/pages/the-animation-process.mdx" /* webpackChunkName: "component---src-pages-the-animation-process-mdx" */),
  "component---src-pages-the-database-process-mdx": () => import("./../../../src/pages/the-database-process.mdx" /* webpackChunkName: "component---src-pages-the-database-process-mdx" */),
  "component---src-pages-the-modeling-process-mdx": () => import("./../../../src/pages/the-modeling-process.mdx" /* webpackChunkName: "component---src-pages-the-modeling-process-mdx" */)
}

